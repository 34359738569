@use '../../styles/index';
@use '../../styles/variables';

.Password {
  h2 {
    margin-top: 2rem;
  }

  .modifyPasswordButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }
}
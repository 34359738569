@use '../../styles/index';
@use '../../styles/variables';

.Cities {
  .actions {
    float: right;
    position: relative;
  }

  .addCityButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }

  button,
  form,
  label {
    margin-bottom: 1rem;
  }

  .card {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .cardTitle {
    margin-left: 15px;
    margin-top: 15px;
  }

  .cardHeader {
    margin-left: 15px;
    margin-top: 15px;
  }

  .categoryCheckbox {
    color: variables.$grey;
    margin-top: 10px;
    width: auto !important;
  }

  .cityImage {
    align-self: center;
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .deletePictureButtonContainer {
    width: auto !important;
  }

  .editPictureButtonContainerWithDelete {
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  .editCityButtonsContainer {
    justify-content: center !important;
    margin-top: 10px;
  }

  .editCityImageButtonsContainer {
    justify-content: center;
    margin-top: 10px;
  }

  .editCityModifyImageButton {
    background-color: variables.$white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: variables.$grey-dark !important;
    color: variables.$grey-dark !important;
    width: auto !important;
  }

  .editCityModifyImageButtonLabel {
    width: auto !important;
    padding-top: 5px;
  }

  .editPictureButtonContainerWithDelete {
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  .editCityCancelButton {
    background-color: variables.$white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: variables.$grey-dark !important;
    color: variables.$grey-dark !important;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 30px;
    width: auto !important;
  }

  .editCityConfirmButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 30px;
    width: auto !important;
  }

  .geographicCityDeleteButton {
    background-color: variables.$red !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 15px;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  .servicesCheckbox {
    float: left;
    position: relative;
    width: auto !important;
  }
}
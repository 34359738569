@use '../../styles/index';
@use '../../styles/variables';

.AlertDeliveryStatus {
  .smsDeliveryStatusNotAvaialbleLabel {
    color: variables.$grey-dark;
    font-family: Roboto;
    font-weight: 300;
  }

  .pie {
    height: 75% !important;
    width: 75% !important;
    margin: auto;
  }
}
@use '../../styles/index';
@use '../../styles/variables';

.Users {
  .activeUserContainer {
    margin-bottom: 50px;
  }

  .addUserButton {
    border-radius: 35px;
    background-color: variables.$green;
    color: variables.$white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  .multiSelectUser {
    z-index: 11 !important;
    margin-top: 15px;
  }
}

//Edit user
.editUserButtonsContainer {
  justify-content: center !important;
}

.editUserCancelButton {
  background-color: variables.$white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: variables.$grey-dark !important;
  color: variables.$grey-dark !important;
}

.editUserConfirmButton {
  background-color: variables.$green !important;
  border-radius: 35px !important;
  color: variables.$white !important;
  margin-left: 10px !important;
}

.editUserFieldContainer {
  margin-top: 10px;
}

.editUserTitle {
  align-self: center;
  color: variables.$purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}
@use '../../styles/index';
@use '../../styles/variables';

.Problem {
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  padding: 8px 10px !important;
  display: list-item !important;

  img {
    height: auto;
    max-height: 175px;
    max-width: 140px;
    width: 100%;
  }

  p {
    color: variables.$grey-dark;
  }

  a:active,
  a:hover,
  a:link,
  a:visited {
    text-decoration: none;
  }

  .ant-rate {
    font-size: 10px;
  }

  .ant-rate-disabled .ant-rate-star {
    cursor: default;
  }

  .containerInList {
    border-width: 0px 0px 0px 0px;
  }

  .date {
    color: #999;
    font-weight: normal;
  }

  .daysSinceLastUpdate {
    border-radius: 20px;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }

  .info {
    margin-bottom: 10px;
  }

  .itemContent {
    padding: 0;
  }

  .itemDescription.row {
    margin: 0;
    max-height: 60px;
    overflow: hidden;
    padding: 0;
  }

  .itemImage {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .itemTitle.row {
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .itemOtherInfo.row {
    margin: 0;
    padding: 0;
    height: 30px;
    font-size: 15px;
    overflow: hidden;
  }

  .other {
    margin: 0;
    max-height: 25px;
    padding: 0;
  }

  .problemCategory,
  .problemStatus,
  .problemNumber {
    font-weight: bold;
    font-size: 1rem;
  }

  .reportedBy {
    color: #999;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .rowProblem.row {
    height: 200px !important;
    padding-top: 0px !important;
  }

  .updatedAtRed {
    background-color: variables.$red;
  }

  .updatedAtOrange {
    background-color: variables.$orange;
  }

  .updatedAtGreen {
    background-color: variables.$green;
  }

  .vl2 {
    border-left: 1px solid #999;
    height: 16px;
    top: 3px;
    margin-right: 7px;
    margin-left: 7px;
  }

  @media screen and (max-width: 1000px) {
    .itemOtherInfo {
      font-size: 10px;
    }
  }
}

.Problem:hover {
  background-color: #e8e8e8;
}
@use '../../styles/index';
@use '../../styles/variables';

.Stats {

  form,
  button,
  input,
  label {
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  .card {
    margin: 0.5rem;
  }

  .numberBigCard {
    h1 {
      font-size: 65px;
      text-align: center;
      color: #725ac1;
    }

    span {
      font-size: 35px;
      color: variables.$black;
    }
  }

  .pie {
    height: 50% !important;
    width: 50% !important;
    margin: auto;
  }

  .title {
    font-size: 24px
  }
}
@use '../../styles/index';
@use '../../styles/variables';

.SingleProblem {
  .cityCommentButton {
    background-color: variables.$green;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }

  .commentRow {
    margin-top: 15px;
  }

  .descriptionContent {
    color: variables.$grey;
    font-weight: normal;
    font-size: 16px;
  }

  .descriptionTitle {
    color: variables.$grey-dark;
    font-weight: bold;
    padding-right: 10px;
    font-size: 18px;
  }

  form,
  button,
  label {
    margin-bottom: 1rem;
  }

  .fullHeight {
    height: 100%;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  .imageloader img {
    width: 100%;
    height: auto !important;
    object-fit: contain;
  }

  .mapContainer {
    height: 300px;
    padding-top: 30px;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .menuIcon {
    margin-top: 15px;
  }

  .menuIconCol {
    display: flex;
    justify-content: right;
    padding: 0;
  }

  .noData {
    justify-content: center;
  }

  p {
    color: variables.$grey-dark;
    word-wrap: break-word;
  }

  .problemHistoryHeader {
    margin-top: 30px;
  }

  .reportCreatorButton {
    background-color: transparent;
    border-radius: 35px !important;
    border-width: 2px;
    border-color: variables.$grey-dark;
    color: variables.$grey-dark !important;
    margin-top: 10px;
  }

  .reportCreatorButtonContainer {
    text-align: center;
  }

  .singleProblemContainer {
    background-color: variables.$grey-light;
    padding-top: 15px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .1);
    height: 100%;
    min-width: 500px;
    min-height: 400px;
  }

  .thumbsIcon {
    flex-basis: 10px;
    margin-top: 0px;
    padding-left: 2px;
  }

  .thumbsStats {
    flex-basis: 15px;
    padding-right: 4px;
  }

  .thumbsUpDownContainer {
    justify-content: center;
    margin-top: 15px;
  }

  .title {
    color: variables.$grey-dark;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .titleCol {
    padding: 0;
  }

  .title h3 {
    margin-top: 20px;
  }
}
@use 'variables';
@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  background-color: variables.$white;
}

.btn-primary {
  background-color: variables.$blue !important;
  letter-spacing: 1px;
}

.citiesSelectContainer {
  margin-top: 20px;
}

.imageInput {
  display: none;
}

.logo_nav {
  max-width: 4.5rem;
  height: auto;
}

.MuiDialog-paperFullWidth {
  min-height: 50%;
  padding: 20px;
}

.css-2b097c-container {
  margin-top: 30px;
  min-height: 40px;
  padding: 0;
}

.map {
  height: 100%;
  width: 100%;
}

.navbar {
  background-color: variables.$blue;
}

.navbar-brand {
  padding: 0px !important;
  height: inherit !important;
  position: relative;
}

.navbar-nav .nav-item .nav-link,
a.nav-link a {
  color: variables.$white !important;
  letter-spacing: 1px;

  &:hover,
  &:focus,
  &.is-active {
    color: variables.$white !important;
    text-decoration: underline;
  }
}

.navbar-toggler {
  color: variables.$off-white !important;
}

.pageSecondTitle {
  color: variables.$purple;
  font-family: 'Roboto';
}

.pageTitle {
  color: variables.$purple;
  font-family: Montserrat;
  font-weight: 500;
  padding-left: 0px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
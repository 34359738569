@use '../../styles/index';

.Invoices {
  h2 {
    margin-top: 2rem;
  }

  .invoiceSelectField {
    margin-top: 15px;
  }

  .invoiceSelectFieldTitle {
    color: #626161;
    margin-top: 20px;
  }
}
@use '../../styles/index';
@use '../../styles/variables';

.Informations {
  .actions {
    float: right;
    position: relative;
  }

  .addImageButton {
    background-color: variables.$white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: variables.$grey-dark !important;
    color: variables.$grey-dark !important;
    margin-top: 20px;
  }

  .addImageLabel {
    width: 100%;
  }

  .card {
    margin-bottom: 20px;
    margin-top: 10px;
    z-index: 0 !important;
  }

  .cardHeader {
    margin-top: 15px;
  }

  .cardTitle {
    font-size: 18px;
  }

  .citiesSelectorContainer {
    margin-top: 15px;
  }

  .deletePictureButtonContainer {
    margin-left: 10px;
    width: auto !important;
  }

  // Edit Information
  .editInformationButtonsContainer {
    justify-content: center !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .editInformationCancelButton {
    background-color: variables.$white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: variables.$grey-dark !important;
    color: variables.$grey-dark !important;
    width: auto !important;
  }

  .editInformationConfirmButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-left: 15px;
    width: auto !important;
  }

  .editInformationImageButtonsContainer {
    justify-content: center;
    margin-top: 10px;
  }

  .editInformationModifyImageButton {
    background-color: variables.$white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: variables.$grey-dark !important;
    color: variables.$grey-dark !important;
    width: auto !important;
  }

  .editInformationModifyImageButtonLabel {
    width: auto !important;
    padding-top: 5px;
  }

  .editPictureButtonContainerWithDelete {
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  .fieldContainer {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
  }

  h2 {
    margin-top: 1em;
  }

  h3 {
    margin-top: 30px;
  }

  .imagePreview {
    max-height: 300px;
    object-fit: contain;
    max-width: 300px;
    margin-top: 20px;
  }

  .informationsContainer {
    margin-bottom: 20px;
  }

  .informationImage {
    align-self: center;
    height: 200px;
    object-fit: contain;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-left: 10px;
    }
  }

  .noData {
    justify-content: center;
    padding-top: 20px;
  }

  .removePictureButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sendInformationButton {
    border-radius: 35px;
    background-color: variables.$green;
    color: variables.$white;
    margin-bottom: 10px;
    margin-top: 20px;

    &:hover {
      background-color: variables.$green;
    }
  }

  .title {
    margin-right: 5px;
    font-weight: bold;
  }
}
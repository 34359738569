@use '../../styles/index';
@use '../../styles/variables';

.Parking {
  .additionalInfo {
    margin-top: 10px;
  }

  .addZoneButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
  }

  .deleteZoneButton {
    background-color: variables.$red !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    bottom: 25px;
  }

  .editParkingInformationsButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 20px;
  }

  h2 {
    margin-top: 2rem;
  }

  h4 {
    margin-top: 50px;
  }

  h5,
  h6 {
    margin-top: 20px;
  }

  .marginTop {
    margin-top: 30px;
  }

  .nightParkingZoneArchive {
    margin-bottom: 50px;
  }

  .saveZoneStatusChangesButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 20px;
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
  }
}

// Log Modal
.log {
  white-space: pre-wrap;
}
@use '../../styles/index';

.Citizens {
  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }
}
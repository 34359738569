@use '../../styles/index';
@use '../../styles/variables';

.Groups {
  padding-bottom: 15px;

  .actions {
    top: 0;
    right: 0;
    position: absolute;
  }

  .addGroupButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }

  .card {
    margin-top: 10px;
    z-index: 0 !important;
  }

  .cardHeader {
    margin-left: 15px;
    margin-top: 15px;
  }

  .citiesSelectFieldTitle {
    color: variables.$grey;
    margin-top: 20px;
  }

  .citiesSelectorContainer {
    margin-top: 15px;
  }

  .cityUserCheckbox {
    margin-right: 10px;
    position: absolute;
    right: 0;
  }

  .cityUserName {
    padding-top: 12px;
  }

  .cityUserRow {
    height: 50px;
  }

  .cityUserRow:hover {
    background-color: #F8F8F8;
    border: 1px solid #ccc;
  }

  .citizenTableContainer {
    margin-top: 15px;
  }

  .citizenTableHeader {
    margin-bottom: 15px;
  }

  .citizenTable {
    position: relative;
    top: 15px;
    height: 420px;
    border: 1px solid #ccc;
    overflow: auto;
    padding: 20px;
  }

  .confirmButton {
    margin-left: 120px;
  }

  // Edit Group
  .editGroupCancelButton {
    background-color: variables.$red !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }

  .editGroupConfirmButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }

  .groupName {
    font-size: 20px;
    font-weight: bold;
  }

  .groupeUserListTitle {
    font-size: 16px;
    font-weight: bolder;
    margin: 0;
  }

  .groupeUserListTitleContainer {
    margin-left: 0px;
  }

  .groupUserRow {
    margin-left: 15px;
  }

  .groupUserRow p {
    margin: 0;
  }

  .groupUserRowEdit {
    height: 40px;
  }

  .groupUserRowEdit:hover {
    background-color: #F8F8F8;
    border: 1px solid #ccc;
  }

  .groupUserNameEdit {
    margin-top: 10px;
  }

  .groupTable {
    position: relative;
    height: 420px;
    overflow: auto;
    padding: 15px;
  }

  .groupTableContainer {
    margin-top: 15px;
  }

  h2 {
    margin-top: 1em;
  }

  h3 {
    margin-top: 30px;
  }

  // Invit Group
  .invitGroupCancelButton {
    background-color: variables.$red !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-left: 10px;
    margin-top: 10px;
    width: auto !important;
  }

  .invitGroupConfirmButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
    margin-left: 10px;
    width: auto !important;
  }

  .modalButtons {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .modalConfirmButton {
    margin-left: 15px;
  }

  .multiSelect {
    margin-top: 15px;
  }

  .noData {
    justify-content: center;
    margin-left: 15px;
  }

  .title {
    padding-right: 5px;
    font-weight: bold;
  }
}
@use '../../styles/index';
@use '../../styles/variables';

.Subscribers {
  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  h4 {
    margin-top: 50px;
  }

  .title {
    color: variables.$grey-dark;
    font-family: Roboto;
    font-weight: 500;
    left: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-top: 25px;
  }
}
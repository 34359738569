@use '../../styles/index';
@use '../../styles/variables';

.markerLabel {
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 245px;

  a {
    text-decoration: none !important;
  }

  img {
    margin: 0px;
    padding: 0px;
    width: 50px;
    height: auto;
    max-height: 80px;
  }

  p {
    color: variables.$grey-dark;
    font-size: 11px;
  }

  .category {
    font-size: 12px;
  }

  .id,
  .id span {
    font-size: 13px;
  }

  .itemDescr {
    padding: 0px;
  }

  .label {
    height: 85px;
  }

  .labelRow {
    display: flex;
    justify-content: space-between;
    width: 225px;
  }

  .status {
    font-size: 12px;
    margin-bottom: 0rem;
  }
}
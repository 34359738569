@use '../../styles/index';
@use '../../styles/variables';

.SurveyDetails {
  .label {
    color: variables.$grey-dark;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
  }

  .title {
    color: variables.$grey-dark;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    padding-right: 5px;
  }

  .pie {
    height: 75% !important;
    width: 75% !important;
    margin: auto;
  }
}
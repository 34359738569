@use '../../styles/index';
@use '../../styles/variables';

.Redirections {
  h2 {
    margin-top: 2rem;
  }

  .facebookButton {
    background-color: #4267B2 !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
    width: 100%;
    padding: 6px 16px;
    border-width: 0px;
  }

  .facebookredirectionLabel {
    color: variables.$grey-dark;
    font-family: Roboto;
    font-weight: 300;
  }

  .facebookredirectionLabelContainer {
    margin-top: 20px;
  }

  .modifyEmailButton {
    background-color: variables.$green !important;
    border-radius: 35px !important;
    color: variables.$white !important;
    margin-top: 10px;
  }
}